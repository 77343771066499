@mixin desktop {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin ultra-narrow-desktop {
    @media (min-width: 1024px) and (max-width: 1160px) {
        @content;
    }
}

@mixin narrow-desktop {
    @media (min-width: 1024px) and (max-width: 1280px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1280px) {
        @content;
    }
}

@mixin wider-desktop {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin widest-desktop {
    @media (min-width: 1536px) {
        @content;
    }
}

@mixin large-screen {
    @media (min-width: 1680px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 768px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: 768px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (min-width: 768px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
        @content;
    }
}

@mixin mobileAndTablet {
    @media (max-width: 1023px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}

@mixin from-tablet {
    @media (min-width: 768px) {
        @content;
    }
}
