@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

[dir='ltr'] .MyAccountAddressBook {
    display: block;
    &-Button {
        position: static;
        @include desktop {
            min-width: 211px;
        }
    }
    &-AddressesHeading {
        display: flex;
        justify-content: space-between;
        margin-top: 65px;
        margin-bottom: 25px;
        @include mobile {
            display: block;
        }

        .MyAccount-Heading {
            margin-bottom: 0;
            @include mobile {
                display: block;
            }
        }
    }
    &-Adresses {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: 1fr;
    }
}
