@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --help-overlay-border-color: #{$blue30};
    --help-overlay-box-shadow-color: rgba(0, 0, 0, 0.15);
}

.AccountManagerOverlay {
    @include desktop {
        position: absolute;
        top: calc(100% + 11px);
        right: -18px;
        padding: 35px 32px;
        background: $white;
        min-width: 229px;
    }

    &_isVisible {
        @include desktop {
            border: 1px solid var(--help-overlay-border-color);
            box-shadow: 0 0 15px 0 var(--help-overlay-box-shadow-color);
            z-index: 2;
        }
    }
    .MyAccountManagerItem {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}
