@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --my-account-tab-list-border-radius: 10px;
}

.MyAccountTabList {
    @include desktop {
        border-radius: var(--my-account-tab-list-border-radius);
        padding: 21px 0;
        border: var(--my-account-content-border);
    }

    &-ExpandableContent {
        &Button {
            @include mobileAndTablet {
                padding: 10px var(--content-padding);

                svg {
                    fill: var(--my-account-tab-list-item-active-color);
                }
            }
        }

        &Heading {
            @include mobileAndTablet {
                font-size: 18px;
                color: var(--my-account-tab-list-item-active-color);
            }
        }

        &Content {
            &_isContentExpanded {
                @include mobileAndTablet {
                    padding-block-start: 0;
                    padding-block-end: var(--content-padding);
                    padding-inline: var(--content-padding);
                }
            }
        }
    }
}
