@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.MyAccountAddressPopup {
    &-DeleteInfo {
        margin: 20px 0;

        @include tablet {
            margin-top: 35px;
        }
    }
    .Button {
        --button-height: 55px;
        --button-hover-height: 55px;

        &_deleteAddress {
            padding: var(--button-padding);
            font-family: $font-primary;
            text-transform: none;
            @include mobile {
                margin-left: 20px;
                margin-right: 20px;
                width: calc(100% - 40px);
            }
            @include tablet {
                margin-left: 20px;
            }
        }
    }
    .Form {
        .FieldForm-Fields {
            margin-top: 28px;
            margin-bottom: 32px;
        }
        .Field_type_checkbox {
            &:nth-of-type(2) {
                margin-bottom: 34px;
            }

            span {
                font-size: 15px;
                color: $grey3;
            }
        }
    }
}
