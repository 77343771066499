$white: #ffffff;
$grey10: #f9fafb;
$grey20: #edf0f3;
$grey30: #e0e3e6;
$grey40: #d0d4d9;
$grey50: #aaafb6;
$grey60: #838d95;
$grey70: #3c404b;
$grey80: #3c404b;
$black: #000000;
$purple: #f4f3fd;
$purple5: #eae8fd;
$purple10: #eae8fd;
$purple20: #d9d0fc;
$purple30: #af9ef0;
$purple40: #8b77da;
$purple50: #5b45b1;
$purple60: #513ba7;
$purple70: #47309e;
$purple80: #3e2b88;
$purple90: #2b177a;
$blue5: #b8c5e7;
$blue10: #a0b2df;
$blue20: #8098d4;
$blue30: #5675c6;
$blue40: #34529b;
$blue50: #1b2a50;
$blue60: #1b2640;
$blue70: #192134;
$blue80: #161c2b;
$blue90: #141824;
$orange5: #f9d7cb;
$orange10: #f7caba;
$orange20: #f4b8a3;
$orange30: #f1a184;
$orange40: #adcee4;
$orange50: #e65724;
$orange60: #b95029;
$orange70: #974629;
$orange80: #7c3e28;
$orange90: #663625;
$green5: #e3f3e7;
$green10: #d9f0df;
$green20: #ccead5;
$green30: #afd6c1;
$green40: #94c9ac;
$green50: #87ce9b;
$green60: #6cb681;
$green70: #56a16b;
$green80: #4c855c;
$green90: #436f50;
$red5: #ffdddd;
$red10: #ffd2d2;
$red20: #ffc3c3;
$red30: #ffafaf;
$red40: #ff9595;
$red50: #ff5959;
$red60: #eb4e4e;
$red70: #dd2d2d;
$red80: #ba2828;
$red90: #982828;
$red-light: #ffdddd;
$red-light2: #ff7171;
$red: #dd2d2d;
$success-background-color: $green5;
$success-color: $green70;
$error-background-color: $red-light;
$error-color: $red;
$default-primary-base-color: $orange50;
$default-primary-dark-color: $orange60;
$default-primary-light-color: $orange40;
$default-secondary-base-color: $blue50;
$default-secondary-dark-color: $blue60;
$default-secondary-light-color: $blue40;
$default-tertiary-base-color: $green50;
$default-tertiary-dark-color: $green60;
$default-tertiary-light-color: $green40;
$grey-dark: #a1a1a1;
$grey: #aaafb6;
$grey2: #e0e3e6;
$grey3: #797979;
$grey4: #d1d1d1;
$grey5: #e7e7e7;
$grey6: #c4c4c4;
$grey7: #8a8989;
$grey-light: #f3f3f3;
$grey-lightest: #edf0f3;
$font-muli: 'Roboto', sans-serif;
$font-standard-size: 62.5%;
$font-primary: 'Tex Gyre Adventor', sans-serif;
$font-secondary: $font-muli;
