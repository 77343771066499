@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.MyAccountContractorsList {
    &-Button {
        min-width: 211px;
        svg {
            margin-right: 14px;
        }
        @include mobileAndTablet {
            margin-bottom: 30px;
        }
        @include desktop {
            position: absolute;
            top: -73px;
            right: 0;
        }
    }
    &-Table {
        font-size: 14px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        tr {
            @include mobile {
                display: flex;
                flex-wrap: wrap;
                padding: 10px 0;
            }
        }

        th:last-of-type,
        td:last-of-type {
            text-align: right;
        }
        .MyAccountContractorsList-NoResults {
            td:last-of-type {
                text-align: left;
            }
        }
        td:first-of-type {
            svg {
                margin-right: 13px;
                width: 19px;
                height: 19px;
                vertical-align: middle;
                cursor: default;
            }
        }
        td:last-of-type {
            svg {
                width: 16px;
                height: 16px;
                margin-right: 15px;
                vertical-align: middle;
                margin-top: -2px;
            }
        }

        .ToggleSwitcher {
            .input-control {
                @include mobile {
                    margin: 0 !important;
                }
            }
        }

        &Label {
            font-size: 13px;
            font-weight: 600;
            margin-right: 10px;
        }

        &Cell {
            @include mobile {
                &_name,
                &_email {
                    width: 100%;
                }

                &_status,
                &_edit {
                    width: 50%;
                }
            }
        }
    }
    &-Pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        align-items: center;

        @include mobile {
            flex-direction: column-reverse;
            align-items: center;
        }

        ul {
            margin: 0;
        }
    }

    &-PageSize {
        display: flex;
        align-items: center;

        @include mobile {
            margin-top: 30px;
        }

        label {
            font-size: 13px;
        }

        .Field {
            margin-top: 0;
            margin-left: 10px;

            &Select  {
                min-width: unset !important;
            }
        }
    }
}
