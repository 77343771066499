@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --wishlist-clear-list-color: #{$default-secondary-base-color};
}

.MyAccountMyWishlist {
    --myaccount-wishlist-action-bar-offset: 80px;

    &-Products {
        @include from-tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        @include wide-desktop {
            inset-block-start: 0;
        }

        @include wider-desktop {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &-EmptyList {
        top: var(--myaccount-wishlist-action-bar-offset);
        @include wide-desktop {
            top: unset;
        }
        @include mobile {
            top: unset;
        }
    }

    &-ActionBar {
        align-items: center;

        @include tablet {
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
        }

        @include mobile {
            padding: 7px 14px;
            bottom: 0;
            z-index: 2;
        }
    }

    &-ShareWishlistButton {
        border: none;
        --hollow-button-height: 47px;
        --hollow-button-hover-height: 47px;
    }

    &-Button.Button {
        --button-padding: 38px;
        --button-hover-padding: 38px;

        @include tablet {
            --button-padding: 20px;
            --button-hover-padding: 20px;
            font-size: 12px;
        }

        @include mobile {
            --button-height: 40px;
        }

        &[disabled] {
            svg {
                fill: $grey70;
            }
        }

        svg {
            fill: var(--color-white);
            margin-inline-end: 16px;

            @include tablet {
                display: none;
            }

            &:hover {
                fill: var(--color-white);
            }
        }
    }

    &-ClearWishlistButton.Button {
        &,
        &:not([disabled]):hover,
        &:not([disabled]):focus {
            min-width: 151px;
            height: 47px;
            border-radius: 7px;
            border: 1px solid var(--wishlist-clear-list-color);
            color: var(--wishlist-clear-list-color);
            text-transform: none;

            @include tablet {
                --button-padding: 20px;
                --button-hover-padding: 20px;
                font-size: 12px;
                min-width: unset;
                margin-left: 10px;
            }
        }
    }
}
