@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.AccountManager {
    color: $grey3;
    padding: 20px 10px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-Component {
        position: absolute;
        top: 0;
        right: 25px;
    }

    > svg {
        width: 8px;
        margin-left: 24px;
    }
    &-Placeholder {
        height: 30px;
        width: 50px;
        margin-right: 50px;
        margin-top: 20px;
    }
    &_isActive {
        @include desktop {
            position: relative;
            z-index: 1000;

            &:before {
                height: calc(100% + 10px);
                top: 0;
                border-radius: 0;
                border: 1px solid $blue30;
                content: '';
                width: calc(100% + 32px);
                left: -16px;
                position: absolute;
                border-bottom: none;
                background: $white;
                box-shadow: 0 -1px 15px -1px var(--header-item-box-shadow-color);
            }

            &:after {
                content: '';
                width: calc(100% + 32px);
                height: 25px;
                top: 100%;
                left: -15px;
                position: absolute;
                background: white;
            }
        }
    }

    span {
        width: 100%;
        display: block;
        white-space: nowrap;
        font-size: 11px;
        line-height: 15px;
        text-align: left;
    }

    &-Bold {
        font-weight: 700;
        color: $black;
    }
}
