@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

:root {
    --my-account-tab-list-item-color: #{$default-primary-base-color};
    --my-account-tab-list-item-active-color: #{$default-secondary-base-color};
}

.MyAccountTabListItem {
    &_isActive,
    &:hover {
        .MyAccountTabListItem-Button {
            color: var(--my-account-tab-list-item-active-color);

            .ChevronIcon {
                fill: var(--my-account-tab-list-item-active-color);
            }
        }
    }

    &-Button {
        font-size: 15px;
        font-weight: 400;
        font-family: $font-secondary;
        color: var(--my-account-tab-list-item-color);

        @include desktop {
            padding: 10px 24px;
            min-width: 230px;
        }

        .ChevronIcon {
            fill: var(--my-account-tab-list-item-color);
        }
    }
}
