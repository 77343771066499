@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.MyAccountOrderPopup {
    font-size: 14px;

    @media print {
        height: auto;

        .NotificationList {
            display: none;
        }
    }

    &-Content {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 20px;
        padding-top: 25px;

        @include desktop {
            grid-gap: 32px;
        }
    }

    &-WrapperContent {
        border: 0;
        overflow-x: hidden;

        @media print {
            overflow: initial;
            min-height: initial;
            height: initial;
            max-height: initial;
        }

        table:not(.MyAccountOrderPopup-Products) {
            th,
            td {
                text-align: left;
                min-width: unset;
            }
        }

        @include desktop {
            min-width: 816px;
        }

        &::-webkit-scrollbar {
            width: 5px;

            @include mobileAndTablet {
                width: 5px;
            }
        }

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-thumb {
            background-clip: padding-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--popup-scroll-accent-color);
            border-right: 0;
        }

        &::-webkit-scrollbar-track {
            background-color: $grey5;
            border-right: 0;
            margin-top: 0;
            margin-bottom: 0;
        }

        .Popup-Header {
            padding-block-end: 0;

            @include mobileAndTablet {
                padding-block-start: 70px;
            }

            @media print {
                padding-block-start: 25px;
            }
        }

        .Popup-CloseBtn {
            inset-block-start: -27px !important;
            inset-inline-end: -27px !important;

            @include mobileAndTablet {
                position: absolute !important;
                inset-block-start: 12px !important;
                inset-inline-end: 12px !important;
            }

            @media print {
                display: none;
            }
        }
    }

    &-Title {
        color: $grey3;
        font-size: 24px;
        font-weight: bold;
        line-height: 31.5px;

        & > span {
            color: $black;
        }
    }

    &-Header {
        width: 100%;

        &:not(&_content) {
            padding-bottom: 25px;

            &:after {
                content: '';
                position: absolute;
                inset-block: -35px 0;
                inset-inline: -35px;
                background-color: #f3f3f3;

                @include mobileAndTablet {
                    inset-block-start: -70px;
                    inset-inline: -21px -16px;
                }
            }
        }

        &_content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        & > * {
            z-index: 1;
        }
    }

    &-ProductsWrapper,
    &-ShippingWrapper,
    &-DocumentsWrapper,
    &-OrderWrapper {
        margin-top: 8px;
    }

    &-ShippingWrapper {
        dl {
            margin-block-end: 25px;

            @include desktop {
                margin-block-end: 40px;
            }
        }
    }

    &-Address,
    &-Products,
    &-Documents,
    &-Order {
        margin: 0;
    }

    &-Order,
    &-Documents,
    &-Products {
        width: 100%;
        table-layout: auto;

        td,
        th {
            letter-spacing: initial;
            vertical-align: middle;
        }
    }

    &-Thumbnail {
        width: 60px;
        height: 60px;
        padding: 0;
    }

    &-Row {
        padding: 10px 0;

        &:nth-child(even) {
            img {
                mix-blend-mode: multiply;
            }
        }

        td {
            &:first-child {
                max-width: 130px;
            }

            &:nth-child(2) {
                @include mobile {
                    min-width: 250px;
                }
            }
        }

        &:first-child {
            img {
                max-width: 120px;
                height: auto;

                @include desktop {
                    max-width: 60px;
                }
            }
        }
    }

    &-Order {
        tr td:last-of-type {
            text-align: right;
        }
    }

    h4 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 8px;
    }

    dt {
        color: $grey3;
    }

    dl {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 6px;

        @include mobile {
            grid-gap: 7px;
        }

        @media print {
            display: block;
        }
    }

    &-Address {
        &:first-of-type {
            .KeyValueTable {
                margin-bottom: 20px;
            }
        }

        .KeyValueTable {
            margin-bottom: 0;
        }
    }

    &-Actions {
        display: inline-grid;
        align-items: center;
        grid-template-columns: repeat(3, auto);
        margin-top: 27px;
        gap: 20px 50px;

        @media print {
            display: none;
        }

        @include mobile {
            grid-template-columns: 1fr;
        }
    }

    &-Button {
        display: inline-flex;
        align-items: center;
        font-weight: 700;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
        color: $black;

        @include desktop {
            &:not(:last-child) {
                &:after {
                    content: '';
                    position: absolute;
                    right: -25px;
                    width: 1px;
                    height: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: $grey4;
                }
            }
        }

        @include tablet {
            &:not(:last-of-type) {
                &:after {
                    content: '';
                    position: absolute;
                    right: -25px;
                    width: 1px;
                    height: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    background: $grey4;
                }
            }
        }

        .Icon {
            &-Reorder {
                height: 16px;
                width: 15px;
            }

            &-Print {
                height: 16px;
                width: 16px;
            }

            &-Download {
                height: 20px;
                width: 17px;
            }
        }

        [class*='Icon-'] {
            margin-right: 12px;
        }
    }
}
