:root {
    --qty-background: #{$white};
    --qty-button-height: 40px;
    --qty-button-width: 42px;
    --qty-button-background: #{$white};
    --qty-button-background-disabled: #{$grey-light};
    --qty-button-border-disabled: #{$grey-light};
    --qty-button-border-color: #{$grey4};
    --qty-button-border-radius: 7px;
    --qty-input-color: #{$black};
}

@mixin qty-input {
    background-color: var(--qty-background);
    margin-right: 24px;
    @include mobile {
        margin-right: 12px;
    }
    input[type='number'] {
        pointer-events: initial;
        width: 64px;
        height: var(--qty-button-height);
        padding: 0;
        color: var(--qty-input-color);
        font-family: $font-secondary;
        margin: 0 2px;
        font-size: 15px;
        line-height: 150%;
        font-weight: 400;
        background-color: var(--qty-button-background);
        border: 1px solid var(--qty-button-border-color);
        &:focus {
            box-shadow: none;
        }

        ~ button {
            width: var(--qty-button-width);
            height: var(--qty-button-height);
            background-color: var(--qty-button-background);
            border: 1px solid var(--qty-button-border-color);

            @include mobile {
                --qty-button-width: 40px;
            }

            &:disabled {
                opacity: 1;
                background-color: var(--qty-button-background-disabled);
                border-color: var(--qty-button-border-disabled);

                svg {
                    fill: $grey4;
                }
            }

            svg {
                width: 20px;
                fill: var(--qty-input-color);
            }
        }
    }
}
