@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.MyAccount {
    &-TabContent {
        &_tab_edit-shopping-list {
            @include from-tablet {
                padding-top: 50px;
            }
        }
    }
}

.MyAccountEditShoppingList {
    margin-bottom: 40px;

    &-Date {
        margin-bottom: 40px;
    }

    .FieldForm-Fields {
        @include desktop {
            max-width: 536px;
        }
    }

    .Field {
        &-Wrapper {
            display: grid;
            align-items: center;
            @include desktop {
                grid-template-columns: 25% auto;
            }

            @include tablet {
                grid-template-columns: 120px auto;
            }
        }

        input {
            @include tablet {
                width: 100%;
            }
        }

        &-AdditionalLabel {
            font-size: 13px;
            margin-top: 24px;
            text-align: right;

            @include desktop {
                padding-right: 25px;
            }

            @include tablet {
                padding-right: 30px;
            }

            @include mobile {
                text-align: left;
            }
        }

        &_type_number {
            margin-top: 0;

            input {
                font-size: 13px;
                font-weight: 400;
                width: 64px;
                padding: 17px 5px;
                border: 1px solid var(--input-border-color);
                pointer-events: auto;
            }

            button {
                border: none;
            }
        }
    }

    &-Table {
        @include from-tablet {
            width: 100%;
        }

        tr {
            th,
            td {
                text-align: center;

                &:first-child {
                    text-align: left;
                    width: calc(35%);
                }

                &:nth-child(2) {
                    padding-left: 0;
                    padding-right: 0;
                }

                &:nth-child(3) {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                &:nth-child(4) {
                    padding-left: 0;
                    padding-right: 0;
                }

                &:last-child {
                    width: 60px;
                    min-width: 60px;
                    text-align: center;
                    padding: 0;
                }
            }
        }
    }

    .Table {
        &-Row {
            td,
            th {
                vertical-align: middle;
            }

            .Button {
                margin-top: 0;
                word-break: break-word;
                height: auto;
                min-height: 40px;
            }
        }
    }

    &-Actions {
        display: flex;
        align-items: center;
        margin-top: 30px;

        @include mobile {
            flex-direction: column;
        }

        .Button {
            @include mobile {
                margin-bottom: 24px;
            }

            &_remove {
                font-size: 13px;
                font-weight: 700;
                color: $black;

                @include from-tablet {
                    margin-right: auto;
                }

                @include mobile {
                    order: 1;
                }
            }

            &_add {
                @include mobile {
                    order: 0;
                }
            }
        }

        .Icon {
            &-Remove {
                margin-right: 10px;
            }
        }
    }

    &-Additional {
        display: grid;
        align-items: flex-start;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
        margin-top: 30px;

        @include mobile {
            grid-template-columns: 1fr;
        }
    }

    &-Summary {
        display: flex;
        justify-content: right;
        text-align: right;

        h4 {
            font-weight: 700;
            margin-block: 0;
            margin-inline-end: 32px;
        }

        .MyAccountEditShoppingList-PriceValue {
            font-size: 18px;
            line-height: 24px;
        }

        .MyAccountEditShoppingList-PriceLabel {
            font-size: 13px;
            line-height: 16px;
        }
    }

    &-QuickAdd {
        &Label {
            font-size: 13px;
            font-weight: 500;
        }

        &ItemWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &ItemName {
            font-size: 16px;
            font-weight: 600;
            margin: 25px 0 10px;
        }

        &Actions {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            width: 100%;
        }

        .SearchField {
            margin: 10px 0;
            padding: 0;
            width: 100%;
            position: static;
            left: 0;
            transform: none;

            &_isVisible {
                .SearchField-SearchInnerWrapper {
                    width: 100%;
                }
            }
        }

        .Field_type_number {
            margin: 0 0 10px;
        }
    }

    &-BackButton {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 13px;
        font-weight: 700;

        &,
        &:not([disabled]):hover {
            color: $black;
            border-bottom: 1px solid $grey5;
            text-transform: uppercase;
            padding: 6px 0;
            text-decoration: none;
        }

        svg {
            width: 12px;
            height: 12px;
            margin-right: 10px;

            path {
                fill: $black;
            }
        }
    }

    &-Product {
        display: flex;
        align-items: center;

        .Image {
            min-width: 78px;
            width: 78px;
            padding-bottom: 78px;
            margin-inline-end: 16px;
            border: 1px solid $grey30;
        }
    }

    &-Details {
        min-width: 180px;
    }

    &-SKU {
        font-size: 13px;
        line-height: 16px;
        color: $grey50;
        word-break: break-word;
    }

    &-Name {
        font-size: 13px;
        line-height: 16px;
    }

    &-Price {
        display: inline-grid;
        grid-template-columns: repeat(2, auto);
        grid-column-gap: 4px;
        align-items: baseline;

        &Label {
            color: #{$grey-dark};
            font-size: 12px;
            line-height: 20px;
        }

        &Value {
            font-size: 13px;
            line-height: 17px;

            &_bold {
                font-weight: bold;
            }
        }
    }

    .Icon {
        &-Remove {
            width: 13px;
            height: 13px;
        }
    }
}
