@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.LoginAccount {
    @include desktop {
        background: linear-gradient(90deg, $grey10 50%, $white 50%);
        padding-top: 20px;
    }
    h3 {
        text-transform: none;
    }
    > div:not(.ContentWrapper) {
        @include desktop {
            margin: 0;
        }
    }
    &-NoAccountShopping {
        margin-top: 27px;
        border-top: 1px solid $grey-light;
        padding-top: 35px;
    }
    &-InnerWrapper .LoginAccount-NoAccountShoppingLink {
        @include desktop {
            margin: 0;
        }
    }
    &-NoAccountShoppingLink {
        color: $black;
        font-weight: bold;
        font-size: 13px;
        line-height: 17px;
        text-transform: uppercase;
        margin-top: 27px;
        margin-right: auto;
        border-bottom: 1px solid $grey5;
        text-decoration: none;
        cursor: pointer;

        &:after {
            content: '\2023';
            position: absolute;
            margin-left: 10px;
            top: 0;
            font-size: 15px;
            right: -15px;
        }
        &:hover {
            color: $black;
        }
    }
    .ContentWrapper {
        @include desktop {
            padding: 0 var(--content-padding);
        }
    }
    &-InnerWrapper {
        @include desktop {
            grid-gap: 0;
        }
        > div {
            @include desktop {
                margin: 0;
            }
        }
        h3 {
            color: $black;
            font-weight: bold;
            font-size: 22px;
            line-height: 32px;
        }
        .Button:not(.Button_likeLink) {
            --button-border-radius: 0;
            --button-hover-border-radius: 0;
            letter-spacing: unset;
            font-size: 13px;
            line-height: 17px;
        }
    }

    &-SignInWrapper {
        @include desktop {
            max-width: unset;
            padding-right: 152px;
            margin: 0;
        }

        .Form {
            .MyAccountOverlay-ForgotPassword {
                color: $black;
                font-weight: bold;
                font-size: 13px;
                line-height: 17px;
                text-transform: uppercase;
                margin-top: 27px;
                margin-right: auto;
                border-bottom: 1px solid $grey5;
                text-decoration: none;
                &:after {
                    content: '\2023';
                    position: absolute;
                    margin-left: 10px;
                    font-size: 15px;
                    right: -15px;
                }
            }
            .MyAccountOverlay-SignInButton {
                max-width: 162px;
            }
        }
    }

    &-CreateAccount {
        @include mobileAndTablet {
            display: block;
        }
        @include desktop {
            max-width: 408px;
            padding-left: 64px;
        }
        h3 + .Button:not(.Button_likeLink) {
            margin-top: 0;
        }
        .CmsBlock-Wrapper {
            h3 {
                margin-bottom: 8px;
            }
            p {
                font-size: 18px;
                line-height: 24px;
                color: $grey3;
            }
        }
    }
}
