@import '../../../../advox-mergo-b2b/src/style/abstract/variables';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/button';
@import '~advox-basic/src/style/abstract/placeholder';

@import '../../../../advox-mergo-b2b/src/style/abstract/button';

.MyAccountMyWholesalers {
    &-Table {
        font-size: 14px;

        @include wide-desktop {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            overflow-x: auto;
            display: block;
            max-width: calc(100vw - (var(--my-account-wrapper-margin-mobile) * 2));
            width: 100%;
            display: table;
        }

        @include mobileAndTablet {
            thead {
                display: none;
            }
            td {
                width: 100%;
                display: inline-block;
            }
        }
        th:first-child,
        td:first-child {
            min-width: unset;
        }
        th:first-child {
            width: 40px;
        }
        th:nth-child(2) {
            width: 250px;
        }
        th:nth-child(3) {
            width: 180px;
        }
        tr:first-child:not(.MyAccountMyWholesalers-NoWholesalers) {
            @include mobileAndTablet {
                border-top: 1px solid $grey30;
            }
        }
        tr:last-child:not(.MyAccountMyWholesalers-NoWholesalers) {
            @include mobileAndTablet {
                border-bottom: 1px solid $grey30;
            }
        }
        td:first-child {
            @include mobileAndTablet {
                position: absolute;
                left: -30px;
                top: 20px;
            }
        }

        td:nth-child(2) {
            @include mobileAndTablet {
                padding: 18px 43px 4px;
            }
        }
        td:last-child {
            @include mobileAndTablet {
                padding: 4px 43px 18px;
            }
        }

        .MyAccountMyWholesalers-NoWholesalers {
            > td {
                width: 100%;
                top: unset;
                position: absolute;
                left: 46px;
                @include mobileAndTablet {
                    position: relative;
                    left: unset;
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }

    &-AddressLabel {
        display: flex;
        align-items: baseline;
    }

    &-MobileLabel {
        font-weight: 700;
        font-size: 13px;
        line-height: 140%;
        margin-right: 17px;

        @include desktop {
            display: none;
        }
    }
    &-Row {
        cursor: pointer;
        td {
            padding: 16px 23px 2px;
            vertical-align: top;
            font-weight: 400;
            font-size: 13px;
            line-height: 24px;

            @include mobileAndTablet {
                padding: 0 43px;
            }
        }
        td > svg {
            width: 8px;
            top: -3px;

            path {
                fill: $black;
            }
        }
        &_isActive {
            background: $grey10;

            .MyAccountMyWholesalers-RowAddressAdditional {
                display: block;
                margin: 5px 0 17px;

                @include mobileAndTablet {
                    margin: 5px 0 0;
                }
            }
        }
    }

    &-RowAddressAdditional {
        display: none;

        p {
            color: $grey70;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 17px;
            margin: 2px 0;
        }
    }

    &-Pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        align-items: center;

        @include mobile {
            flex-direction: column-reverse;
            align-items: center;
        }

        ul {
            margin: 0;
        }
    }

    &-PageSize {
        display: flex;
        align-items: center;

        @include mobile {
            margin-top: 30px;
        }

        label {
            font-size: 13px;
        }

        .Field {
            margin-top: 0;
            margin-left: 10px;

            &Select  {
                min-width: unset !important;
            }
        }
    }

}
